<template>
  <div class="sm:c-col-4 md:c-col-8 lg:c-col-6">
    <p-form
      @submit="onFormSubmit"
      v-slot="{ invalid }"
    >
      <!-- Título -->
      <div class="c-row">
        <div class="c-col">
          <div class="c-text-subtitle">
            Esqueci minha senha
          </div>
        </div>
      </div>

      <!-- Subtítulo -->
      <div class="c-row ui-mb-4">
        <div class="c-col">
          <div class="c-text-b2">
            Para receber o link de redefinição da senha, informe o email cadastrado.
          </div>
        </div>
      </div>

      <!-- Email -->
      <div class="c-row ui-mb-4">
        <validation-provider
          class="c-col"
          rules="required|email"
          name="Email"
          v-slot="{ errors }"
        >
          <p-input
            v-model="email"
            data-cy="loginField"
            label="Email"
            type="email"
            :error-message="errors[0]"
          />
        </validation-provider>
      </div>

      <separator />

      <!-- Botões -->
      <div class="c-row ui--mb-4">
        <!-- Enviar -->
        <div class="c-col-2 sm:c-col ui-mb-4">
          <c-button
            data-cy="registerButton"
            size="lg"
            type="submit"
            class="ui-w-full"
            :disabled="invalid"
            :loading="isFormSending"
          >
            Enviar
          </c-button>
        </div>

        <!-- Voltar -->
        <div class="c-col-2 sm:c-col ui-mb-4">
          <template v-if="$slots.return">
            <slot name="return" />
          </template>
          <router-link
            v-else
            :to="{ name: 'login', query: { ...$route.query } }"
          >
            <c-button
              outline
              class="ui-w-full"
              data-cy="registerButton"
              size="lg"
            >
              Voltar
            </c-button>
          </router-link>
        </div>
      </div>
    </p-form>
  </div>
</template>

<script>
import { CButton } from '@estrategiahq/coruja-web-ui';
import Separator from '@/components/separator';
import PForm from '@/components/p-form';
import PInput from '@/components/p-input';
import { ValidationProvider } from 'vee-validate';
import { notAuthorizedError } from '@/utils/errors';

export default {
  props: {
    authPopover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    email: '',
    isFormSending: false,
  }),
  components: {
    PForm,
    PInput,
    CButton,
    Separator,
    ValidationProvider,
  },
  mounted() {
    this.email = this.$route.query.email;
  },
  methods: {
    async onFormSubmit() {
      this.isFormSending = true;

      const allowedList = ['target', 'type'];
      const search = allowedList
        .map(key => ([key, this.$route.query[key]]))
        .filter(entry => entry[1] != null);

      const params = new URLSearchParams(search);

      const location = `${window.location.origin}/recuperar-senha?${params.toString()}`;
      try {
        await this.$services.accounts.sendResetPasswordEmail(this.email, location);
        if (!this.authPopover) {
          this.$router.push({ name: 'esqueci-senha-sucesso', query: { ...this.$route.query } });
        } else {
          this.$emit('recovery-password');
        }
      } catch (e) {
        if (e === notAuthorizedError) {
          this.$toast.show({
            type: 'error',
            text: 'Não foi possível concluir a ação. Contate o suporte.',
            timeout: 0,
          });
        }
      } finally {
        this.isFormSending = false;
      }
    },
  },
};
</script>
