<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div class="sm:c-col-4 md:c-col-8 lg:c-col-6">
    <div>
      <p-form
        ref="form"
        @submit="onSubmit"
        v-slot="{ invalid }"
      >
        <!-- Título -->
        <div class="c-row ui-mb-4">
          <div class="c-col">
            <div class="c-text-subtitle">
              Crie uma conta no Estratégia
            </div>
          </div>
        </div>

        <!-- Nome completo -->
        <div class="c-row ui-mb-4">
          <validation-provider
            class="c-col"
            rules="required|full_name"
            name="Nome"
            v-slot="{ errors }"
          >
            <p-input
              data-cy="nameField"
              key="nameField"
              label="Nome*"
              name="name"
              v-model.trim="form.name"
              :error-message="errors[0]"
              :mask="fullNameRegex"
            />
          </validation-provider>
        </div>

        <!-- Email -->
        <div class="c-row ui-mb-4">
          <validation-provider
            class="c-col"
            rules="required|email"
            name="Email"
            vid="email"
            v-slot="{ errors }"
          >
            <p-input
              data-cy="emailField"
              key="emailField"
              label="Email*"
              name="email"
              v-model.trim="form.email"
              :error-message="errors[0]"
            />
          </validation-provider>
        </div>

        <!-- Senha / Confirmar senha -->
        <div class="c-row ui-mb-4">
          <validation-provider
            class="c-col-2 sm:c-col"
            rules="required|min:8"
            name="Senha"
            vid="password"
            v-slot="{ errors }"
            :custom-messages="{
              required: 'Senha é obrigatória',
              min: 'A senha é muito curta'
            }"
          >
            <input-password
              data-cy="passwordField"
              key="passwordField"
              label="Senha*"
              name="password"
              v-model.trim="form.password"
              :error-message="errors[0]"
              icon-info="info"
            />
          </validation-provider>
        </div>

        <div
          v-if="errors.length > 0"
          class="q-mt-md text-negative"
        >
          <q-list>
            <div
              v-for="(error, index) in errors"
              :key="index"
            >
              * {{ error }}
            </div>
          </q-list>
        </div>

        <!-- Checkbox Política de Privacidade -->
        <div class="c-row ui-mb-4">
          <div class="c-col">
            <div class="c-text-b2 c-text-gray">
              <c-checkbox v-model="checkbox">
                Li, entendi e concordo com a
                <a
                  @click.stop
                  :href="politicaDePrivacidadeHref"
                  target="_blank"
                >
                  <b>
                    <u>Política de privacidade</u>
                  </b>
                </a>
              </c-checkbox>
            </div>
          </div>
        </div>

        <!-- Botão Continuar -->
        <div class="c-row">
          <div class="c-col">
            <c-button
              type="submit"
              :disabled="invalid || !checkbox"
              size="lg"
              :loading="isFormSending"
              class="ui-w-full"
            >
              Continuar
            </c-button>
          </div>
        </div>
      </p-form>

      <!-- Texto Já tem conta - Faça login -->
      <div class="c-text-b2 c-text-gray ui-mt-4">
        <span class="ui-font-semibold">
          Já possui conta?
        </span>
        <slot name="login" />
      </div>

      <template v-if="showSocialLogin">
        <div
          class="ui-flex c-text-gray-300 ui-mt-4"
          style="gap: 20px;"
        >
          <div style="width: 100%">
            <separator />
          </div>
          Ou
          <div style="width: 100%">
            <separator />
          </div>
        </div>
        <!-- Social Login Botões -->
        <div class="ui-mb-8">
          <div class="social-section">
            <social-login-button
              provider="facebook"
              @logged-in="handleLoggedIn"
              :prevent-redirection="true"
            />
            <social-login-button
              provider="google"
              @logged-in="handleLoggedIn"
              :prevent-redirection="true"
            />
            <social-login-button
              provider="apple"
              @logged-in="handleLoggedIn"
              :prevent-redirection="true"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { CCheckbox, CButton } from '@estrategiahq/coruja-web-ui';
import { User } from '@/models/user';
import PInput from '@/components/p-input';
import PForm from '@/components/p-form';
import Separator from '@/components/separator';
import InputPassword from '@/components/input-password';
import SocialLoginButton from '@/components/p-social-login-button';
import illustration from '@/assets/images/register-illustration.svg';
import { politicaDePrivacidadeHref } from '@/consts';
import { userAlreadyExistsError } from '@/utils/errors';
import { ValidationProvider } from 'vee-validate';
import { FULL_NAME_REGEX } from '@/utils/regex';

export default {
  name: 'TrialUserForm',
  components: {
    SocialLoginButton,
    CCheckbox,
    CButton,
    PInput,
    PForm,
    InputPassword,
    Separator,
    ValidationProvider,
  },
  data() {
    return {
      illustration,
      politicaDePrivacidadeHref,
      form: {
        name: '',
        email: '',
        password: '',
      },
      errors: [],
      checkbox: false,
      isFormSending: false,
      showSocialLogin: false,
      fullNameRegex: FULL_NAME_REGEX,
    };
  },
  mounted() {
    const hideSocial = this.getBoolParam('hide-social');
    if (!hideSocial) {
      this.$socialLogin.someActive().then((someActive) => {
        this.showSocialLogin = someActive;
      });
    }

    const hideSignup = this.getBoolParam('hide-signup');
    if (hideSignup) {
      this.$router.push({
        name: 'login',
        query: { ...this.$route.query },
      });
    }
  },
  methods: {
    getBoolParam(name) {
      try {
        const val = JSON.parse(this.$route.query[name]);
        return !!val;
      } catch (_) {
        return false;
      }
    },
    async onSubmit() {
      this.isFormSending = true;
      try {
        const userData = new User({
          name: this.form.name,
          email: this.form.email,
        });

        const body = {
          full_name: userData.name,
          email: userData.email,
          password: this.form.password,
        };
        await this.$services.accounts.createTrialUser(body);

        this.$services.events.notifyManualRegistration();

        this.$emit('created-user', body);
      } catch (error) {
        this.errors = error.response?.data?.errors || [];

        this.$toast.show({
          type: 'error',
          text: 'Erro ao realizar o cadastro',
        });

        if (error === userAlreadyExistsError) {
          this.$refs.form.setErrors({
            email: ['Email já cadastrado'],
          });
        }
      } finally {
        this.isFormSending = false;
      }
    },
    handleLoggedIn(val) {
      this.$emit('logged-in', val);
    },
  },
};
</script>

<style>
.text-negative {
  color: var(--c-color-red-500);
}
.social-section {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 15px;
}
</style>
